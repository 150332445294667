<template>
  <LayoutSwipeList class="mb-10" :container="container">
    <SwiperSlide v-for="(item, index) in items" :key="index">
      <PaperTeaser :paper="item" type="slider" />
    </SwiperSlide>
  </LayoutSwipeList>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'

withDefaults(defineProps<{ container?: boolean }>(), {
  container: true,
})

const indexStore = useIndexStore()
const items = computed(() => indexStore.activePapers)
</script>
